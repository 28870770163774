(() => {
  const addVideoStopper = () => {
    window.Bus.on('emu-modal:close', ({ id }) => {
      const plyr = document.querySelector(
        `[data-component="modal"][data-id=${id}] [data-component="video-plyr"]`
        //@ts-ignore
      )?.plyr;
      plyr?.stop?.();
    });
  };

  const init = () => {
    addVideoStopper();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
