(() => {
  // handles the close button event
  const handleCloseBtn = () => {
    const openMenuBtn = document.getElementById('header__menu-trigger');

    window.Bus.on('emu-button:click', ({ id }) => {
      // when close button is clicked, click the open button
      if (id === 'header__menu-close-trigger') {
        openMenuBtn?.click();
      }
    });
  };

  const init = () => {
    handleCloseBtn();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
