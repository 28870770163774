(() => {
  // resizing the carousel height whenever the window is resized
  const adjustCarouselDimensions = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    let winWidth = window.innerWidth;

    if (carouseInstances?.length) {
      // once the window load event happened, setting the carousel height
      window.addEventListener('load', () => {
        carouseInstances.forEach(inst => {
          // making sure that the carousel height is updated when every image is loaded
          setTimeout(() => {
            // @ts-ignore
            inst?.updateSliderHeight?.();
          }, 2000);
        });
      });

      const adjustCarouselHeight = () => {
        carouseInstances.forEach(inst => {
          // @ts-ignore
          inst?.updateSliderHeight?.();
        });
      };
      adjustCarouselHeight();

      // if the carousel's content width is
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          adjustCarouselHeight();
        }
      });
    }
  };

  const init = () => {
    adjustCarouselDimensions();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
