// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

// import favicon here.
import './resources/images/favicon.png';
import './pages/**/*.scss';

import './menu';

import './video-in-modal';

import './components/find-nearest-clinic/find-nearest-clinic';
import { clinicsInit } from './pages/clinics/clinics';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    _googleMapInstances: any; // google map instances
  }
}

(() => {
  // accordions' content when they have image is getting hidden as the height calculations are being done before the image gets loaded
  // re-calculating the height of panels when accordion is toggled, to make sure that the panels are shown without chopping off content and without any white spaces
  const handleAccordions = () => {
    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }
      }
    });
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      //@ts-ignore
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // scrolls to find clinic section and focuses the input present in it
  const showFindClinicSection = () => {
    const $findClinic = document.querySelector('#find-clinic') as HTMLElement;
    const $inp = $findClinic?.querySelector(
      '.find-clinic__input input'
    ) as HTMLInputElement;

    const getClinicPos = () => {
      return Math.round(
        window.pageYOffset + $findClinic.getBoundingClientRect().top - 70 // funky value for making sure that the element is on the top of the page, excluding the header height
      );
    };

    if ($findClinic) {
      let timer;

      const scrollToClinic = () => {
        const pos = getClinicPos();
        const currentScrollDist = Math.round(window.scrollY);
        if (currentScrollDist !== pos) {
          window.scrollTo({
            top: pos,
            behavior: 'instant',
          });
          clearTimeout(timer);
          timer = setTimeout(debounce(scrollToClinic, 250));
        } else {
          window.scrollTo({
            top: pos,
            behavior: 'instant',
          });
          clearTimeout(timer);
          $inp?.focus?.();
        }
      };
      scrollToClinic();
    }
  };

  // for any link that has #find-clinic, if there is an element with id as find-clinic scroll the page to the section and focus the input
  // if there is no element with find-clinic as id, navigate to home page and then scroll to find-clinic section
  const handleFindClinicLinks = () => {
    if (sessionStorage.getItem('showFindClinic') === 'true') {
      showFindClinicSection();
      sessionStorage.removeItem('showFindClinic');
    }

    const $links = document.querySelectorAll('a[href*="#find-clinic"]');
    $links.forEach($el => {
      $el.addEventListener('click', e => {
        e.preventDefault();
        const $findClinic = document.querySelector(
          '#find-clinic'
        ) as HTMLElement;
        if ($findClinic) {
          showFindClinicSection();
        } else {
          sessionStorage.setItem('showFindClinic', 'true');
          window.location.href = window.location.origin;
        }
      });
    });
  };

  const init = () => {
    // listen to events here
    handleAccordions();
    handleFindClinicLinks();

    clinicsInit();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
