(() => {
  const CONSTANTS = {
    containerClass: 'find-clinic',
    inputContainerClass: 'find-clinic__input',
    inputHasValueClass: 'has-value',
    labelTopClass: 'on-top',
    buttonClass: 'find-clinic__button',
    redirectUrlClass: 'find-clinic__redirect-url',
    searchParam: 'location',
  };

  const handleUnload = (input: HTMLInputElement) => {
    window.addEventListener("unload", () => {
      input.value = "";
    });
  };

  const handleFACWidget = () => {
    const facContainers = document.querySelectorAll(
      `.${CONSTANTS.containerClass}`
    );
    facContainers?.forEach(facContainer => {
      initFindClinic(facContainer);
    });
  };

  const initFindClinic = facContainer => {
    // Disable the search button initially
    const searchButton = facContainer.querySelector(
      `.${CONSTANTS.buttonClass}`
    ) as HTMLButtonElement;
    searchButton?.setAttribute('disabled', 'true');

    // Setup event listeners for input element
    const inputElement = facContainer.querySelector(
      `.${CONSTANTS.inputContainerClass} input`
    ) as HTMLInputElement;
    inputElement?.addEventListener(
      'input',
      handleInputChange.bind(null, searchButton, facContainer)
    );
    handleUnload(inputElement);
    // Setup event listener for button click
    searchButton?.addEventListener(
      'click',
      handleSubmit.bind(null, inputElement, facContainer)
    );
  };

  const handleInputChange = (
    searchButton: HTMLButtonElement,
    facContainer: HTMLElement,
    e
  ) => {
    const facInput = e?.target;

    const label = facContainer?.querySelector(
      `.${CONSTANTS.inputContainerClass} label`
    ) as HTMLElement;

    if (facInput && searchButton) {
      if (facInput?.value === '') {
        // Disable search button if input is empty
        if (!searchButton?.attributes.getNamedItem('disabled')) {
          searchButton?.setAttribute('disabled', 'true');
        }

        // Vertically center the label and make it bigger
        label?.classList.remove(CONSTANTS.labelTopClass);

        // Adjust top/bottom paddings of input
        facInput?.classList.remove(CONSTANTS.inputHasValueClass);
      } else {
        // Enable search button if input is non-empty
        searchButton?.removeAttribute('disabled');

        // Move label towards the top and make it smaller
        label?.classList.add(CONSTANTS.labelTopClass);

        // Adjust top/bottom paddings of input
        facInput?.classList.add(CONSTANTS.inputHasValueClass);

        // Only allow alphanumeric values and space
        e.target.value = facInput.value.replace(/[^a-z0-9\ ]/gi, '');
      }
    }
  };

  const handleSubmit = (
    inputElement: HTMLInputElement,
    facContainer: HTMLElement,
    e
  ) => {
    e?.preventDefault();
    const value = inputElement?.value;

    // Fetch the redirect url
    let anchorElement = facContainer?.querySelector(
      `.${CONSTANTS.redirectUrlClass} a`
    ) as HTMLAnchorElement;
    let redirectUrl = anchorElement.href;

    // Add query param with value and redirect
    const processedRedirectUrl = new URL(redirectUrl);
    processedRedirectUrl.searchParams.append(CONSTANTS.searchParam, value);

    const ghostLink = document.createElement('a');
    ghostLink.target = '_self';
    ghostLink.href = processedRedirectUrl.href;
    ghostLink.click();
  };

  const init = () => {
    handleFACWidget();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
